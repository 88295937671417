// Basic element rules
html { 
    font-size: 62.5%; 
     -webkit-font-smoothing: antialiased; 
    @media only screen and (-webkit-min-device-pixel-ratio: 1.25), only screen and ( min-device-pixel-ratio: 1.25), only screen and ( min-resolution: 200dpi), only screen and ( min-resolution: 1.25dppx) {     -webkit-font-smoothing: subpixel-antialiased; } 
    height: 100%;
} 

body {
    font-family: $f-default;
    font-weight: $fw-normal;
    height: 100%;
    font-size: 1.7rem;
    color: $c-white;
    background-color: $c-black;
    overflow: hidden;
    b { 
        font-family: $f-default; 
        font-weight: $fw-heavy;
    }
    i, em { 
        @include s-script;
    }
    & > * {
        width: 100%;
    }
    &.blog, &.single-post {
        background-color: $c-grey;
        .main {
            background-color: transparent;
        }
    }
}

body#tinymce {
    height: auto;
}

a {
    color: $c-grey;
    @include transition(color 0.3s);
    &:hover {
        color: $c-white;
    }
}

a, a:hover, a:active, a:focus {
    text-decoration: none;
}

ul, li {
    list-style: none;
    margin: 0rem;
    padding: 0rem;
}

.wrap {
    position: fixed;
    top: 0rem;
    right: 0rem;
    bottom: 0rem;
    left: 0rem;
}

.wrap, .content, .main, .sidebar {
    height: 100%;
}

.content {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.sidebar-primary .main {
    width: 100%;  
    max-height: 100%;  
    padding: 0rem;
    background-color: rgba($c-black, 0.6);
}
@media(min-width: $screen-sm-min){
    .sidebar-primary .main {
        padding-right: $sidebar-width;
    }
}

.m-mobile-toggle {
    position: absolute;
    top: 1rem;
    padding: 0rem;
    display: inline-block;
    width: auto;
    z-index: 1080;
    background-color: transparent;
    border: 0rem;
    font-size: 3rem;
    opacity: 1;
    pointer-events: auto;
    @include transition(opacity 0.3s);
    &.s-toggle-hidden {
        opacity: 0;
        pointer-events: none;
        @include transition(opacity 0.3s);
    }
}

.l-content-inner {
    max-width: 90rem;
    height: 100%;
    padding: 1.5rem;
}

:focus {
    outline: 0;
}