.m-fp-logo {
    position: absolute;
    top: 0rem;
    left: 0rem;
    font-size: 13rem;
    padding: 2.5rem;
    right: 0rem;
    text-align: center;
    z-index: 1050;    
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media(min-width: $screen-xs-min){        
        font-size: 15rem;
        padding: 4.5rem;
        right: auto;
        display: inline-block;
    }
}