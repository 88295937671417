#m-open-sidebar {
    right: 1.5rem;
}
@media only screen and (min-width: $screen-sm-min) {
    #m-open-sidebar {
        display: none;
    }
}
.l-sidebar {
    text-transform: uppercase;
}
.sidebar .nano-content {
    padding: 1rem 5rem;
    @media only screen and (min-width: $screen-sm-min) {
        padding: 1rem;
    }
}
.l-sidebar .brand {
    margin: 3rem 0rem;
    margin-top: 2.5rem;
    font-size: 13rem;
    display: inline-block;
    outline: 0rem;
    @media only screen and (min-width: $screen-sm-min) {
        padding: 2rem;
    }
}
.l-sidebar .brand img {
    max-width: 100%;
}
.m-sidebar-contact-social-networks {
    margin-top: 5rem;
}
.m-sidebar-contact-social-networks li {
    display: inline-block;
    font-size: 3.2rem;
    a {
        color: $c-white;
    }
    &.email {
        margin-right: 0.7rem;
    }
}
.m-sidebar-contact-primary {
    font-weight: $fw-heavy;
    margin-bottom: 2rem;
}

.sidebar-primary .sidebar {
    background-color: $c-blue;
    text-align: center;
    padding: 1.5rem;
    width: 100%;
    position: absolute;
    top: 0rem;
    right: 0rem;
    bottom: 0rem;
    z-index: 1060;
    @include transform(translate3d(100%,0,0));
    @include transition-transform;
    &.s-panel-open {
        @include transform(translate3d(0%,0,0));
        @include transition-transform;        
    }
    &.nano > .nano-content {
        // padding-right: 1rem;
    }
}
.m-sidebar-contact-phone a {
    color: $c-white;
}
.m-attribution-murmur {
    font-size: 1.2rem;
    margin-top: 3rem;
    padding: 1rem;
    display: inline-block;
    letter-spacing: 0.05em;
}
@media(min-width: $screen-sm-min){
    .sidebar-primary .sidebar {
        right: 0rem;
        width: $sidebar-width;
        @include transform(translate3d(0%,0,0));
        @include transition-transform;
    }
}