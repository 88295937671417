.nano {
    height: 100%;
    outline: 0rem;
}

.nano-content {
    padding: 3rem;
    outline: 0rem;
}

.nano>.nano-pane {
    opacity: 0 !important;
}

.l-content-inner .nano-content {
    padding: 3rem 5rem 3rem 1.5rem;
}

.nano>.nano-content:focus {
    outline: none;
}