// SVG background image
// @include svg(filename, 100px 40px center top)
@mixin svg($image-name, $image-size, $image-position) {
  background-image: url(../images/#{$image-name}.png);
  background-image:
    linear-gradient(transparent, transparent),
    url(../images/#{$image-name}.svg);
  background-size: $image-size;
  background-position: $image-position;
  background-repeat: no-repeat;
}

@mixin s-script() {
    font-family: $f-script;
    font-style: normal;
    font-size: 1.5em;
    line-height: 1em;
} 

@mixin m-button-active() {
    color: $c-blue;
    background-color: rgba($c-white, 1.0);
    @include transition(background-color 0.3s, color 0.3s);
}

@mixin m-button() {
    padding: 0.5rem 2rem;
    border: 0.1rem solid $c-white;
    color: $c-white;
    background-color: rgba($c-white, 0.0);
    display: inline-block;
    text-transform: uppercase;
    white-space: normal;
    // @include transform(translateY(0px));
    @include transition(background-color 0.3s, color 0.3s);
    &:hover {
        @include m-button-active();
    }
    // &:active {        
    //     @include transform(translateY(2px));
    //     @include transition-property(transform);
    //     @include transition-duration(0.2s);    
    // }
} 

@mixin m-button-filled() {
    @include m-button();
    border: 0.1rem solid $c-grey;
    color: $c-white;
    background-color: $c-grey;
    @include transition(background-color 0.3s, color 0.3s);
}

@mixin transition-transform {
    @include transition-property(transform);
    @include transition-duration(0.6s);
    @include transition-timing-function($ease-out-quart);
}