.page-header {
    margin: 0rem;
    margin-bottom: 1rem;
    border: 0rem;
}
.page-header h1 {
    margin: 0rem;
    margin-top: 0.5rem;
    text-transform: uppercase;
    font-weight: $fw-light;
    letter-spacing: 0.05em;
    font-size: 2.5rem;
    @media(min-width: $screen-xs-min){
        font-size: 3.2rem;
    }
    @media(min-width: $screen-sm-min){
        font-size: 4.2rem;        
    }
}
.l-content-inner li {
    list-style: disc outside;
    margin-left: 1em;
    &.gfield {
        margin-left: 0em;
    }
}
.m-title-post-title {
    margin: 0rem;
    margin-top: 0.5rem;
    font-size: 1.7rem;
    font-weight: $fw-heavy;
}
.m-title-seal {
    display: inline-block;
    height: 4rem;
    width: auto;
}
.m-bio-images {
    // display: flex;
    max-width: 100%;
    margin-top: 1rem;
    img  {
        margin-right: 1.5rem;
        &:last-child {
            margin-right: 0rem;
        }
    }
}
.m-jobs-list-header {
    text-transform: uppercase;
    font-size: 2rem;
    margin-bottom: 0.25rem;
    font-weight: $fw-heavy;
    letter-spacing: 0.05em;
    &:after {
        content: ":";
    }
}
.m-jobs-list-job {
    margin-bottom: 3rem;
}
.m-jobs-list-job h4 {
    text-transform: uppercase;
    margin-bottom: 0rem;
}
.m-jobs-list-location {
    font-style: italic;
    font-weight: normal;
    margin: 0rem;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
}
.m-jobs-list-description li {
    list-style: square;
}
.m-page-buttons {
    margin-top: 1rem;
    li {
        list-style: none;
    }
}


.about {
    .main  {
        background-color: rgba($c-black, 0.3);
    }
}

@media(min-width: $screen-sm-min){
    .about, .bio, .testimonials, .job-opportunities {
        .main  {
        }
        .l-content-inner {
            max-width: 70rem;
        }
    }   
}

@import "layouts/pages/home";