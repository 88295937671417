.l-content-inner {
    .validation_error {
        color: lighten(red, 30%);
        &:before {
            content: "  ";
            font-family: 'steelhead';
        }
    }
    .gform_body {
        margin: 2rem 0rem;
        position: relative;
        ul, li {
            list-style: none;
        }
        .gfield {
            width: 100%;
            margin-top: 1.5rem;
            @media(min-width: $screen-md-min){
                width: 48%;
            }
        }
        .gfield_label {
            text-transform: uppercase;
            letter-spacing: 0.05em;
        }
        .l-list-dropdown {
            display: none;
        }
        .validation_message {
            padding-top: 0.3rem;
            color: lighten(red, 30%);
            &:before {
                content: "  ";
                font-family: 'steelhead';
            }
        }
        input[type="text"], input[type="tel"], input[type="email"], textarea {
            background: transparent;
            border: 0.1rem solid $c-white;
            padding: 0.5rem;
            width: 100%;
            font-size: 0.8em;
        }
        input[type="radio"], input[type="checkbox"] {
            display:none;
        }
        input[type="radio"] + label:before, input[type="checkbox"] + label:before {
            display:inline-block;
            width:2rem;
            height:1rem;
            margin-right: 1rem;
            content: " ";
            border: 0.1rem solid $c-white;
            background-color: rgba($c-white, 0.0);
            cursor:pointer;
        }
        input[type="radio"]:checked + label:before, input[type="checkbox"]:checked + label:before {
            background-color: rgba($c-white, 1.0);
        }
        input[type="checkbox"] + label, input[type="radio"] + label {
            padding-left: 3rem;
            &:before {
                margin-left: -3rem;
            }
        }
        .gfield_checkbox, .gfield_radio {
            li {
                margin-left: 0rem;
            }
        }
        .m-button {
            width: 100%;
            padding: 0.5rem 1rem;
            position: relative;
        }
        .sh-arrow {
            right: 1rem;
            position: absolute;
            font-size: 2.4rem;
            @include transform(rotateX(0deg));
            @include transition-property(transform);
            @include transition-duration(0.3s);
        }
        .m-form-select-open.s-is-open .sh-arrow {
            @include transform(rotateX(180deg));
            @include transition-property(transform);
            @include transition-duration(0.3s);
        }
        .m-form-select-open {
            margin-bottom: 1rem;
        }
        // contact us + proposal request
        .m-contact-inquire, .m-proposal-time, .m-proposal-found, .m-resume-time, .m-resume-found {
            margin: 0rem;
            padding-left: 0.5rem;
            & > label {
                display: none;
            }
            input[type="text"] {
                width: auto;
                border: 0rem;
                border-bottom: 0.1rem solid $c-white;
            }
        }
        // proposal request + resume submission
        .m-proposal-level, .m-resume-level {
            position: static;
            @media(min-width: $screen-md-min){
                position: absolute;
                top: 0rem;
                right: 0rem;
            }
        }
        // resume submission
        .ginput_container_fileupload {
            display: none;
        }
        .m-resume-upload label {
            @include m-button();
            text-align: center;
            width: 100%;
            &.s-file-selected:before {
                font-family: 'steelhead';
                content: " ";
            }
        }
        // candidate other
        .m-resume-other, .m-proposal-other {
            position: static;
            @media(min-width: $screen-md-min){
                position: absolute;
                right: 0rem;
                top: 48rem;
            }
        }
    }
    .gform_footer {
        width: 100%;
        @media(min-width: $screen-md-min){
            width: 48%;
        }
    }
    .gform_button {
        width: 100%;
    }
}

label {
    font-weight: $fw-medium;
}

//search 
.m-search-form {
    border: 0.1rem solid $c-white;
    padding: 0.5rem;
    margin-bottom: 2rem;
    label {
        margin: 0rem;
    }
    &:before {
        content: "";
        display: inline;
        font-family: 'steelhead';
    }
    input {
        border: 0rem solid $c-white;
        background-color: transparent;
    }
}