#menu-primary-navigation {
    padding-top: 2rem;
    li {
        margin-bottom: 1.5rem;
        text-transform: uppercase;
        font-size: 1.7rem;
        letter-spacing: 0.05em;
        font-weight: $fw-heavy;
    }
    li.active a {
        color: $c-white;
    }
    li.active.m-button-wrapper a {
        color: $c-blue;
    }
    .m-button-wrapper a {
        width: 100%;
        @media(min-width: $screen-xs-min){
            width: 29rem;
        }
    }
    & > .m-button-wrapper {
        margin-top: 4rem;
    }
    & > .m-button-wrapper ~ .m-button-wrapper {
        margin-top: 0rem;   
    }
}