.m-button {
    @include m-button();
}

.m-button-wrapper {
    a {
        @include m-button();
    }
    &.active a {
        @include m-button-active();
    }
}

input[type="submit"] {
    @include m-button-filled();
}