.m-blog-index-column .nano-content {
    padding: 0rem;
}
#m-open-archive-mobile {
    left: 1.5rem;
    text-transform: uppercase;
    display: none;
    font-size: 2.5rem;
    font-weight: $fw-light;
    .sh-caret {        
        position: relative;
        padding-top: 2px;
    }
    .sh-caret:before {
        display: inline-block;
        @include transform(rotate(0deg));
        @include transition-property(transform);
        @include transition-duration(0.3s);
    }
    &.s-open .sh-caret:before {
        @include transform(rotate(-180deg));
    }
}
body.blog, body.single-post {
    .main {
        a {
            color: $c-blue;
            &:hover {
                color: lighten($c-blue, 20%);
            }
        }
    }
    #m-open-archive-mobile {
        display: inline-block;
    }
    @media only screen and (min-width: $screen-lg-min) {
        #m-open-archive-mobile {
            display: none;
        }
    }
}

.m-blog-archive-list {
    padding: 1.5rem;
    padding-top: 5.5rem;
    height: 100%;
    position: absolute;
    top: 0rem;
    max-width: 100%;
    z-index: 1070;
    background-color: $c-grey;
    @include transform(translate3d(-100%,0,0));
    @include transition-transform;
    &.s-panel-open {        
        @include transform(translate3d(0%,0,0));
        @include transition-transform;
    }
    a {
        color: $c-blue;
        // display: inline-block;
        &:hover {
            color: lighten($c-blue, 20%);
        }
    }
}
    @media only screen and (min-width: $screen-lg-min) {    
        .m-blog-archive-list {
            padding-top: 1.5rem;
            position: static;
            @include transform(translate3d(0%,0,0));
            @include transition-transform;
        }
    }
.m-blog-archive-list-thumb {    
        width: 6rem;
        margin-right: 1rem;
        float: left;
}
.m-blog-archive-list .nano-content {
    padding-right: 3rem;
}
.m-blog-archive-list h4 {
    text-transform: uppercase;
    letter-spacing: 0.05em;
}
.m-blog-archive-list li {
    padding: 1.5rem 0rem;
    border-top: 0.1rem solid $c-white;
    align-items: center;
    &:last-child {
        border-bottom: 0.1rem solid $c-white;
    }
    .m-blog-archive-post-title {
        text-transform: uppercase;
        font-weight: $fw-book;
        margin-left: 7rem;
        display: block;
        font-size: 1.5rem;
    }
    img {
        height: auto;
        width: 100%;
    }
}
.m-blog-post-list {
    height: 100%;
    padding-top: 5.5rem;
    padding-bottom: 1.5rem;
}
@media only screen and (min-width: $screen-lg-min) {
    .m-blog-post-list {        
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }
}
.m-blog-post-list article {
    padding-top: 3rem;
    padding-right: 3rem;
    &:first-child {
        padding-top: 0rem;
    }
}
.m-blog-post-list article h3 {
    text-transform: uppercase;
    letter-spacing: 0.05em;
}
.m-blog-post-list article img {
    max-width: 100%;
    height: auto;
    margin-bottom: 0.5rem;
}
.m-post-entry-title {
    text-transform: uppercase;
    letter-spacing: 0.05em;
}

body.search-results .entry-title {
    margin-top: 0rem;
}